.privacy-heading {
  font-size: 2em;
  margin-bottom: 10px;
}

.privacy-subheading {
  font-size: 1.5em;
  margin-top: 20px;
  margin-bottom: 10px;
}

.privacy-text {
  font-size: 1.1em;
  text-align: left;
  margin: 10px 0;
}

.privacy-list {
  text-align: left; /* Ensure the list is left-aligned */
}

.privacy-list li {
  font-size: 1.1em; /* Ensure the font size matches other text */
}

a {
  color: #E91E63;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}