 body {
   margin: 0;
   font-family: Arial, sans-serif;
   background: linear-gradient(to bottom, #E91E63, #3F51B5);
   color: white;
   text-align: center;
   overflow-x: hidden;
   display: flex;
   flex-direction: column;
   min-height: 100vh;
   /* Prevent horizontal scrolling */
 }

 html,
 body {
   min-height: 100vh;
 }

 .App {
   display: flex;
   flex-direction: column;
   flex: 1;
   min-height: 100vh;
 }

 .header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   width: 100%;
   padding: 3px 40px;
   background-color: white;
   color: black;
   position: fixed;
   z-index: 1000;
 }

 .header .left-spacer,
 .header .right-spacer {
   flex: 0 0 100px;
 }

 .header .logo-container {
   display: flex;
   align-items: center;
   cursor: pointer;
 }

 .header .logo {
   width: 40px;
   margin-right: 10px;
 }

 .header .logo,
 .header .logo-text {
   cursor: pointer;
   align-items: center;
   vertical-align: middle;
 }

 .header img {
   width: 40px;
   margin-right: 10px;
 }

 .header .logo-text {
  color: black;
   font-weight: bold;
   margin-right: 20px;
 }

 .header .logo-text:hover {
  text-decoration: none;
 }
 
 .header nav {
   display: flex;
   flex-grow: 1;
 }

 .header nav a {
   margin: 0 15px;
   text-decoration: none;
   color: black;
   font-weight: bold;
   position: relative;
 }

 .header nav a:hover,
 .header nav a.active {
   text-decoration: underline;
 }

 .header .contact-button {
   margin-left: auto;
   margin-right: 50px;
 }

 button {
   position: relative;
   display: inline-block;
   margin: 10px;
   padding: 10px 15px;
   text-align: center;
   font-size: 18px;
   letter-spacing: 1px;
   text-decoration: none;
   color: #3F51B5;
   background: transparent;
   cursor: pointer;
   transition: ease-out 0.5s;
   border: 2px solid #3F51B5;
   border-radius: 10px;
   box-shadow: inset 0 0 0 0 #3F51B5;
 }

 button:hover {
   color: white;
   box-shadow: inset 0 -100px 0 0 #3F51B5;
 }

 button:active {
   transform: scale(0.9);
 }

 .main {
   display: flex;
   flex-direction: column;
   align-items: center;
   padding: 100px 20px 20px;
   border-radius: 5px;
   background-color: #3F51B5;
   width: auto;
   height: auto;
   max-width: 400px;
   min-height: 100%;
   margin: 0 auto;
   flex: 1;
 }

 .main-content {
   display: flex;
   flex-direction: column;
   flex: 1;
   align-items: center;
   justify-content: center;
   min-height: 100%;
 }

 .main>* {
   margin: 10px 0;
 }

 .main h1 {
   font-size: 1.5em;
   margin: 0;
 }

 .main p {
   margin: 0;
   font-size: 1em;
 }

 .phone-container {
   display: flex;
   justify-content: center;
   align-items: center;
   position: relative;
   width: 250px;
   height: 500px;
   margin: 20px 0;
   background-size: contain;
 }

 .phone-container video {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   object-fit: contain;
   border-radius: 25px;
 }

 .download-now {
   color: #E91E63;
 }

 .download-buttons {
   display: flex;
   flex-direction: column;
   align-items: center;
 }

 .download-buttons img {
   margin: 10px;
   width: 150px;
 }

 .terms-and-policy a {
   color: #E91E63;
 }

 .footer {
   display: flex;
   justify-content: space-between;
   align-items: flex-start;
   width: 100%;
   padding: 30px 20px;
   background-color: white;
   color: black;
   box-sizing: border-box;
   margin-top: auto;
 }

 .footer-left {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   margin-left: 100px;
 }

 .footer-logo-container {
   display: flex;
   align-items: center;
   margin-bottom: 10px;
 }

 .footer-logo {
   width: 40px;
   margin-right: 10px;
 }

 .footer-logo-text {
   font-size: 1.5em;
   font-weight: bold;
 }

 .footer-text {
   margin: 10px 0;
   font-size: 0.9em;
   text-align: left;
   white-space: normal;
 }

 .footer-text a {
   color: #E91E63;
   text-decoration: none;
 }

 .footer-social-icons {
   display: flex;
   margin-top: 10px;
 }

 .footer-social-icons a img {
   width: 20px;
   margin-right: 10px;
 }

 .footer-center {
   display: flex;
   flex-direction: column;
   align-items: center;
   flex-grow: 1;
 }

 .footer-links {
   display: grid;
   grid-template-columns: repeat(4, auto);
   gap: 30px;
   text-align: left;
 }

 .footer-links a {
   color: black;
   text-decoration: none;
   font-size: 0.9em;
 }

 .footer-right {
   display: flex;
   flex-direction: column;
   align-items: flex-end;
   margin-right: 100px;
 }

 .footer-right h3 {
   font-size: 1.2em;
   margin: 0 0 10px 0;
   font-weight: bold;
 }

 .footer-right p {
   margin: 0 0 10px 0;
   font-size: 0.9em;
   color: #E91E63;
 }

 .footer-download-buttons {
   display: flex;
   flex-direction: column;
   align-items: flex-end;
 }

 .footer-download-buttons a img {
   width: 135px;
   margin: 5px 0;
 }

 @media (max-width: 900px) {
  .footer-links {
    grid-template-columns: repeat(3, auto);
  }
}

@media (max-width: 800px) {
  .footer-links {
    grid-template-columns: repeat(2, auto);
  }
}

 @media (max-width: 750px) {
   .footer {
     flex-direction: column;
     align-items: center;
     padding: 20px;
   }

   .footer-left,
   .footer-center,
   .footer-right {
     margin: 10px 0;
     align-items: center;
   }

   .footer-left {
     margin-left: 0;
     text-align: center;
   }

   .footer-right {
     margin-right: 0;
     text-align: center;
   }

   .footer-logo-container {
     flex-direction: column;
     align-items: center;
   }

   .footer-logo {
     margin-right: 0;
   }

   .footer-logo-text {
     margin-top: 10px;
   }

   .footer-text {
     text-align: center;
   }

   .footer-social-icons {
     justify-content: center;
   }

   .footer-links {
     grid-template-columns: 1fr;
     gap: 10px;
     text-align: center;
   }

   .footer-download-buttons {
     align-items: center;
   }
 }

 /* Hide the burger icon by default */
 .burger-icon {
   display: none;
   cursor: pointer;
 }

 @media (max-width: 760px) {
   .header {
     justify-content: space-between;
   }

   .header .logo-container {
     margin-right: auto;
   }

   .header .burger-icon {
     display: block;
     margin-left: auto;
     padding: 10px;
     padding-right: 50px;
   }

   .header nav {
     display: none;
     flex-direction: column;
     align-items: center;
     width: 100%;
     background-color: white;
     position: absolute;
     top: 60px;
     left: 0;
     z-index: 999;
     box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
   }

   .header nav a {
     margin: 10px 0;
     text-align: center;
     width: 100%;
     padding: 0;
   }

   .header .contact-button {
     display: none;
   }

   .header nav.open {
     display: flex;
     justify-content: center;
     align-content: center;
   }
 }